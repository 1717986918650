// Load jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

$(window).scroll(function(){
    var sticky = $('.header'),
        scroll = $(window).scrollTop();
  
    if (scroll >= 100) sticky.addClass('__fixed');
    else sticky.removeClass('__fixed');
});

$(document).ready(function() {
    $(".statSlider").each(function() {
        var thisElement = $(this);
        thisElement.slick({
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            variableWidth: true,
            centerMode: true,
            arrows: false,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        variableWidth: true,
                        centerMode: false,
                    }
                },
            ]
        });
    });
});

// Load BS
require('bootstrap');

//Load Libraries
require('magnific-popup/dist/jquery.magnific-popup.min');
require('chart.js/dist/chart');
// require('chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels');
// import ChartDataLabels from 'chartjs-plugin-datalabels';


//Load Modules
require('./modules/accordion');
require('./modules/charts');
require('./modules/menu');


