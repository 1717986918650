// Chart.js
$('.chart-doughnut.__round').each(function() {
    var ctx = $(this).get(0);
    var dataValue = $(this).data('value');
    var dataEmpty = 100 - dataValue;

    if ($(this).data('color')) {
        var dataColor = $(this).data('color');
    } else {
        var dataColor = '#0072BC';
    }

    var chart = new Chart(ctx, {
        type: 'doughnut',
        data: {
            datasets: [
                {
                    data: [dataValue, dataEmpty],
                    backgroundColor: [dataColor, 'rgba(50,78,94,.5)'],
                    borderWidth: 0,
                    borderRadius: [30, 0]            
                }
            ]
        },
        options: {
            cutout: '80%',
           
            aspectRatio: 1,
            legend: {
                display: false
            },
            plugins: {
                legend: {
                    display: false,
                }
            },
            tooltips: {
                enabled: false
            }
        }
    });
});